import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import ArrowRight from '../images/arrow--right.svg'

const Wrapper = styled.p`
margin: 20px 0 0;
  text-align: center;

  a {
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;

    // Faux -webkit-tap-highlight-color for more rounded designs
    @media screen and (pointer: coarse) {
      &:active {
        background-color: ${props => props.theme.colors.webkitTapHighlight};
      }
    }

    svg {
      width: 13px;
      position: relative;
      top: 1px;
      margin-left: 6px;
    }
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    margin-top: 50px;
  }
`

const LinkAsButton = ({ to, title, ...props }) => (
  <Wrapper>
    <Link
      className={`btn--pill${props.className ? ` ${props.className}` : ''}`}
      to={to}
    >
      {title}
      <ArrowRight />
    </Link>
  </Wrapper>
)

export default LinkAsButton
