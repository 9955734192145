import React, { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import Layout from '../components/Layout'
import LinkAsButton from '../components/LinkAsButton'
import { PageContext } from '../context/ApplicationState'

const Container = styled.div`
  justify-content: center;
`

const Header = styled.div`
  margin-bottom: 10px;
  text-align: center;

  .section-title {
    margin-top: 12px;
  }

  .headline--intro {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
  }
`

const NotFoundPage = ({ location }) => {
  const { pageState, setPageState } = useContext(PageContext)
  useEffect(() => {
    setPageState(pageState => ({
      ...pageState,
      hasStickySiteNav: true,
      hasHeroSection: false,
    }))
  }, [])

  useEffect(() => {
    document.querySelector('body').classList.add('has-light-header')
    return () =>
      document.querySelector('body').classList.remove('has-light-header')
  }, [])

  return (
    <Layout pageName="Other" location={location}>
      <Helmet>
        <title>404 - Page Not Found</title>
        <meta name="description" content="Page not found" />
      </Helmet>

      <Container className="wrap padded-section">
        <Header>
          <p className="headline--hero" style={{ fontStyle: 'normal' }}>
            😬
          </p>
          <h1 className="section-title">Error</h1>
          <p className="headline--intro">
            The page you're looking for can't be found.
          </p>
          <LinkAsButton to="/" title="Go Home" />
        </Header>
      </Container>
    </Layout>
  )
}
export default NotFoundPage
